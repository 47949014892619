<template>
  <v-dialog
      v-model="visibility"
      max-width="800"
      content-class="cross__dialog"
      :persistent="changed"
      :transition="$dialogTransition"
      @click:outside="showConfirmDialog"
      @keydown.enter="onCreate()"
      scrollable
  >
    <v-card>
      <v-fade-transition>
        <v-progress-linear
            v-if="loading"
            absolute
            top
            indeterminate
            color="primary"
        ></v-progress-linear>
      </v-fade-transition>
      <v-slide-x-transition>
        <div class="cross__inner" v-if="!loading">
          <v-btn fab small elevation="0" @click="showConfirmDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <v-card-title>
        {{
          type == "create" ? $t("form.create_a_news") : $t("form.edit_a_news")
        }}
      </v-card-title>
      <v-card-text class="pb-5 pt-4">
        <div>
          <v-row
          ><v-col cols="12">
            <t-img
                @success="changeImage"
                @reset="resetImage"
                @editChangeImage="editChangeImage"
            /> </v-col
          ></v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputNews.title"
                  :label="$t('form.title')"
                  :maxlength="100"
                  hide-details="auto"
                  :error-messages="titleErrors"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                  outlined
                  dense
                  color="primary"
                  v-model="inputNews.text"
                  :label="$t('offersCatalog.description')"
                  rows="5"
                  :maxlength="3000"
                  :error-messages="textErrors"
              />
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters justify="space-between">
          <v-col cols="6" class="pr-1">
            <v-btn
                dark
                block
                class="br-5 text-transform-none"
                @click="showConfirmDialog"
            >{{ $t("btn.cancel") }}</v-btn
            >
          </v-col>
          <v-col cols="6" class="pl-1">
            <v-btn
                v-if="type === 'edit'"
                block
                class="btn-orange-bg text-transform-none br-5"
                :disabled="!changed"
                :loading="loading"
                @click="onUpdate()"
            >{{ $t("btn.update") }}</v-btn
            >
            <v-btn
                v-if="type === 'create'"
                block
                class="btn-orange-bg text-transform-none br-5"
                :disabled="!changed"
                :loading="loading"
                @click="onCreate()"
            >{{ $t("btn.create") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ConfirmLeftDialog
        :visible="visibleConfirm"
        @close="visibleConfirm = false"
        @delete="onClose"
    />
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import dialogMixin from "@/mixins/dialogMixin";
import notifications from "@/mixins/notifications";
import tImgMixin from "@/mixins/tImgMixin";
import _ from "lodash";
import EventBus from "@/events/EventBus";
import newsService from "@/services/request/news/newsService";

export default {
  data: () => ({
    loading: false,
    changed: false,
    visibleConfirm: false,
    inputNews: {},
    copyNews: {}
  }),
  mixins: [dialogMixin, validationMixin, notifications, tImgMixin],
  validations: {
    inputNews: {
      title: { required },
      text: { required },
    },
  },
  components: {
    ConfirmLeftDialog,
  },
  props: {
    type: {
      required: true,
    },
    article: {
      required: true,
      default: {}
    }
  },
  mounted() {
    this.inputNews = _.cloneDeep(this.article);
    this.copyNews = _.cloneDeep(this.article);
    this.T_IMG_OBJECT = this.inputNews;
    this.T_IMG_COPY_OBJECT = this.copyNews;
    this.T_IMG_MEDIA_KEY = "image";
    if (this.inputNews.image) {
      EventBus.$emit("is-selected-image", this.inputNews.image);
    }
  },
  methods: {
    onClose() {
      this.$v.$reset();
      this.$emit("close");
    },
    showConfirmDialog() {
      this.changed ? (this.visibleConfirm = true) : this.onClose();
    },
    async onCreate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          const formData = new FormData();
          if(this.inputNews.image) {
            formData.append("image", this.inputNews.image);
          }
          if(this.inputNews.title) {
            formData.append("title", this.inputNews.title);
          }
          if(this.inputNews.text) {
            formData.append("text", this.inputNews.text);
          }

          const createdArticle = await newsService.createArticle(formData);
          this.setSuccessNotification(
              this.$t("home.successfully_created_article")
          );
          this.$v.$reset();
          this.$emit('articleCreated', createdArticle);
          this.$emit("close");
        } catch (e) {
          this.loading = false;
          this.setErrorNotification(e.message);
        } finally {
          this.loading = false;
        }
      }
    },
    async onUpdate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          const formData = new FormData();
          formData.append("_method", 'patch');
          const changedData = this.$getChangedData(this.inputNews, this.copyNews);
          changedData.forEach((data) => {
              formData.append(data.field, data.value ? data.value : '');
          });
          const changedItem = await newsService.updateArticle(
              this.inputNews.id,
              formData
          );
          this.$v.$reset();
          this.setSuccessNotification(
              this.$t("home.successfully_updated_stream")
          );
          this.$emit('articleModified', changedItem);
          this.visibility = false;
        } catch (e) {
          this.setErrorNotification(e.message);
        } finally {
          this.loading = false;
        }
      }
    },
  },
  computed: {
    titleErrors() {
      const errors = [];
      if (!this.$v.inputNews.title.$dirty) {
        return errors;
      }
      !this.$v.inputNews.title.required &&
      errors.push(this.$t("form.errors.TitleRequired"));
      return errors;
    },
    textErrors() {
      const errors = [];
      if (!this.$v.inputNews.text.$dirty) {
        return errors;
      }
      !this.$v.inputNews.text.required &&
      errors.push(this.$t("form.errors.DescriptionRequired"));
      return errors;
    },
  },
  watch: {
    inputNews: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.copyNews, this.inputNews);
      },
    },
  },
};
</script>

<style>
.auth-input .v-input__control {
  display: flex;
  align-items: flex-start;
}

</style>
