<template>
  <div>
    <v-card>
      <VCardTitle class="headline pb-0 d-flex justify-space-between" :class="{ 'px-0': $vuetify.breakpoint.smAndDown }">
        <v-btn plain :color="$vuetify.theme.dark ? 'grey lighten-3' : 'grey'" dense @click="onBack">
          <v-icon left>mdi-chevron-left</v-icon>{{ $t("btn.back") }}
        </v-btn>
        <v-slide-x-reverse-transition>
          <v-menu
              v-if="permissions.can_add_edit_article"
              offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="cursor-pointer"
                  icon
                  v-on="on"
                  v-bind="attrs"
                  small
              >
                <v-icon color="black lighten-1" dense>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list  class="pa-0" dense>
              <v-list-item
                  @click="articleDialog = true;"
                  link
                  class="pa-0"
              >
                <v-row no-gutters>
                  <v-btn
                      text
                      class="pl-0"
                  >
                    <v-icon class="mx-2" color="black lighten-1" dense>
                      mdi-pencil
                    </v-icon>
                    {{ $t("form.edit_article") }}
                  </v-btn>
                </v-row>
              </v-list-item>
              <v-list-item
                  @click="deleteArticleDialog = true;"
                  link
                  class="pa-0"
              >
                <v-row no-gutters>
                  <v-btn
                      text
                      class="pl-0"
                  >
                    <v-icon class="mx-2" color="red lighten-1" dense>
                      mdi-delete
                    </v-icon>
                    {{ $t("btn.remove_article") }}
                  </v-btn>
                </v-row>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-slide-x-reverse-transition>
      </VCardTitle>

      <v-card-text class="pa-0">
        <v-container>
          <v-row>
            <v-col cols="12" md="5">
              <v-img
                  v-if="!article.image"
                  src="https://via.placeholder.com/400x300"
                  alt="News Image"
                  class="news-image"
              ></v-img>
              <v-img
                  v-if="article.image"
                  :src="article.image"
                  alt="News Image"
                  class="news-image"
                  contain
              ></v-img>
            </v-col>

            <v-col cols="12" md="7">
              <div class="d-flex align-center">
                <div class="text-h5 font-weight-bold">{{ article.title }}</div>
              </div>

              <v-card class="mt-4">
                <v-card-text>
                  <div class="mb-1">{{ article.text }}</div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <add-edit-news-dialog v-if="articleDialog" :visible="articleDialog" :article="article" @close="articleDialog = false" @articleModified="articleModified" :type="'edit'"/>
    <deleteConfirmDialog
        :visible="deleteArticleDialog"
        @close="deleteArticleDialog = false"
        @delete="deleteArticle"
    />
  </div>
</template>

<script>

import newsService from "@/services/request/news/newsService";
import loader from "@/mixins/loader";
import AddEditNewsDialog from "@/components/news/addEditNewsDialog.vue";
import DeleteConfirmDialog from "@/components/dialog/deleteConfirmDialog.vue";
import notifications from "@/mixins/notifications";
import user from "@/mixins/user";

export default {
  name: "ArticleDetailsPage",
  components: {DeleteConfirmDialog, AddEditNewsDialog},
  mixins: [ loader, notifications, user],
  data: () => ({
    article: {},
    loading: false,
    articleDialog: false,
    deleteArticleDialog: false
  }),
  async created() {
    await this.getArticle();
  },
  methods: {
    onBack() {
      this.$router.push({ name: "news" }).catch(() => {});
    },
    async getArticle() {
      try {
        this.setLoading(true);
        this.article = await newsService.getArticle(this.$route.params.article);
      } catch (e) {
        console.log(e)
      } finally {
        this.setLoading(false);
      }
    },
    articleModified(modifiedArticle) {
        this.article = {...modifiedArticle};
    },
    async deleteArticle() {
      await newsService.deleteArticle(this.article.id);
      this.deleteArticleDialog = false;
      this.$router.push({ name: "news" }).catch(() => {});
      this.setSuccessNotification(this.$t("profile.successfully_deleted"));
    },
  },
};
</script>

<style scoped>
.text-h5, .font-weight-bold, .subtitle-1 {
  color: inherit !important;
}
</style>