// eslint-disable-next-line import/no-cycle
import requestService from "../requestService";

const prefix = '/articles';

export default {
  async getArticle(articleId) {
    const response = await requestService.get(`${prefix}/${articleId}`);
    return response?.data?.data;
  },
  async getArticles(params) {
    const response = await requestService.get(`${prefix}`,
        {...params}
        );
    return response?.data;
  },
  async createArticle(payload) {
    const response = await requestService.post(`${prefix}`, payload);
    return response?.data?.data;
  },
  async updateArticle(articleId, payload) {
    const response = await requestService.post(`${prefix}/${articleId}`, payload);
    return response?.data?.data;
  },
  async deleteArticle(articleId, payload) {
    const response = await requestService.delete(`${prefix}/${articleId}`, payload);
    return response?.data?.data;
  },
};
